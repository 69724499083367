.message-header {
    height: 8%;
    background-color:  rgba(61, 66, 63, 0);
}
.message-message {
    background-color: rgba(240, 248, 255, 0.822);
    background-size: cover; 
    border-radius: 10px;
    
}
.message-menu {
    background-color: rgba(61, 66, 63, 0);
}
.message-footer {
    height: 8%;
}
