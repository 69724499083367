/* CalendarApp.css */

/* Estilos para el contenedor del calendario */
.calendar-container {
    margin: 5px;
    padding: 5px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.52);
    font-size: 12px;
  }
  
  /* Estilos para el título del calendario */
  .calendar-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Estilos para el párrafo de la fecha seleccionada */
  .selected-date {
    font-size: 10px;
    color: #333;
  }
  .modal-overlay {
    z-index: 1000; /* Ajusta según sea necesario */
  }
  .calendar-component {
      height: 70%;
      width: 100%;
      background-color: #333;
      color: #fff;
      border: 1px solid #555;
      border-radius: 10px;
      font-size: 8px;
  
  }
  
  